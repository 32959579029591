//BASE
@import "../base/variables";
@import "../base/mixin";


footer {
    &.footer {
        position: relative;
        border-top-color: transparent;
        box-shadow: 0 0 25px rgba(26, 108, 225, 0.1);
        padding: 25px 15px;

        @include tablet-landscape {
            padding: 15px 20px;
            margin-bottom: 100px;
        }


        .footer_content {
            .column {
              

                @include tablet-landscape  {
                    margin-bottom: 15px;
                }
                h6 {
                    color: $orange;
                    font-weight: bold;
                    margin-bottom: 10px;
                    letter-spacing: 0;
                }

                a {
                    font-size: 14px;
                    text-decoration: underline;
                    text-transform: none;
                    color: #637388;

                    &:hover {
                        color: $orange;
                    }
                }

                span {
                    font-size: 12px;
                    text-transform: none;
                    letter-spacing: 0;
                }

                p {
                    text-transform: none;
                    font-size: 14px;
                    margin-bottom: 0;

                    a {
                        text-decoration: underline;
                        font-weight: 500;

                    }
                }

            }

        }


    }
}

// .content.content-fixed {
//     .card.card-body {
//         min-height: 50vh;
//     }
// }