//BASE
@import "../base/mixin";

.create_search_query {
    label {
        &.check_wrap {
            display: flex;
            align-items: center;

            @include tablet-landscape {
                align-items: flex-start;
                margin-bottom: 20px;
            }

            input[type='checkbox'] {
                margin-right: 10px;

                @include tablet-landscape {
                    margin-top: 5px;
                }
            }
        }
    }

    .search_tab_wrapp {
        @include tablet-landscape {
            flex-flow: column;
        }

        .__querysearch {
            position: absolute;
            right: 25px;
            top: 405px;

            @include tablet-landscape {
                position: static;
            }

        }

        .horizontal_tabs {

            max-width: calc(100vw - 80px);
            overflow: hidden;

            .horizontal_tabs_wrap {
                @include tablet-landscape {
                    overflow: hidden;
                    overflow-x: auto;
                    white-space: nowrap;
                }

                button {

                    @include tablet-landscape {
                        position: relative;
                        top: -2px;
                        box-shadow: none;
                        outline: none;
                    }

                }
            }

        }
    }

    .tab_listing {

        .list_col {
            h6 {
                color: #e35132;
                font-weight: bold;
                margin-bottom: 10px;
                letter-spacing: 0;
            }
        }

    }
}