.rdt_Table {
    .rdt_TableHead {
        .rdt_TableHeadRow {
            .rdt_TableCol[data-column-id="1"]  {
                position: sticky;
                left: 0;
                background: white;
                z-index: 9;
            }

            .rdt_TableCol {
                @include tablet-landscape  {
                    width: 120px;
                    max-width: 120px;
                }
                
            }
        }
    }
    .rdt_TableBody {
        .rdt_TableRow {
            .rdt_TableCell[data-column-id="1"] {
                position: sticky;
                left: 0;
                background: white;
                z-index: 9;
            }

            .rdt_TableCell {
                @include tablet-landscape  {
                    width: 120px;
                    max-width: 120px;
                }
            }
        }
    }
}

.table_body_content {
    @media (max-width: 991.98px) {
        margin-bottom: 15px;
    }
}

.button_wrap {
    &>button {
        margin-left: 0;

        @media (max-width: 991.98px) {
            width: calc(50% - 5px);
        }
    }
}

.filter_icon_added {
    &::before {
        content: '';
        width: 15px;
        height: 15px;
        background: url(../../assets/images/filter.png) no-repeat;
        background-size: 100%;
        float: left;
        margin-right: 5px;
        margin-top: 3px;
    }
}

.export-icon {
    @media (max-width: 991.98px) {
        margin-right: 0;
    }
    &::before {
        content: '';
        width: 15px;
        height: 15px;
        background: url(../../assets/images/export.png) no-repeat;
        background-size: 100%;
        float: left;
        margin-right: 5px;
        margin-top: 3px;
    }
}

.edit-icon {
    @media (max-width: 991.98px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    &::before {
        content: '';
        width: 15px;
        height: 15px;
        background: url(../../assets/images/editing.png) no-repeat;
        background-size: 100%;
        float: left;
        margin-right: 5px;
        margin-top: 3px;
    }

    &:hover {
        &:before {
            background: url(../../assets/images/editing_white.png) no-repeat;
        background-size: 100%;
        }
        
    }
}
