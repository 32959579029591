//BASE
@import "../base/variables";
@import "../base/mixin";


.__dashboard_page {
    .__page_head_bar {


        .save_query {
            &.create_screen_btn {
                @include tablet-landscape {

                }
                a {
                    @include tablet-landscape {
                        width: 100%;
                        padding: 8px 13px;
                    }  
                }
            }
        }
    }

    .card-widget {
        &.card-contacts {
            @include tablet-landscape {
               
            }
            .card-header {
                h6 {
                    font-size: 16px;
                    color: $orange;
                }
            }

            .card-body {
                .flex-row {
                    a {
                        &.btn {
                            &.btn-sm {
                                @include tablet-landscape {
                                    width: 100%;
                                    margin: 0px 0;
                                    color: $black;
                                    border: 0;
                                    text-align: left;
                                    padding: 0;
                                    letter-spacing: 0;
                                    font-size: 15px;
                                    text-transform: capitalize;
                                    line-height: 1.2;
                                    margin-bottom: 10px;
                                    font-weight: normal;
                                    text-decoration: underline;
                                    display: flex;
                                    align-items: center;

                                    &:before {
                                        content: "";
                                        width: 7px;
                                        height: 7px;
                                        float: left;
                                        background: $orange;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    hr {
        @include tablet-landscape {
            margin: 0;
        }
       
    }

}
