// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

// // ---------------| FONT IMPORTS |--------------
// @font-face {
//   font-family: poppins-thin;
//   src: url(../assets/fonts/poppins/Poppins-Thin.ttf);
// }

// @font-face {
//   font-family: poppins-extra-light;
//   src: url(../assets/fonts/poppins/Poppins-ExtraLight.ttf);
// }

// @font-face {
//   font-family: poppins-light;
//   src: url(../assets/fonts/poppins/Poppins-Light.ttf);
// }

// @font-face {
//   font-family: poppins-regular;
//   src: url(../assets/fonts/poppins/Poppins-Regular.ttf);
// }

// @font-face {
//   font-family: poppins-medium;
//   src: url(../assets/fonts/poppins/Poppins-Medium.ttf);
// }

// @font-face {
//   font-family: poppins-semi-bold;
//   src: url(../assets/fonts/poppins/Poppins-SemiBold.ttf);
// }

// @font-face {
//   font-family: poppins-bold;
//   src: url(../assets/fonts/poppins/Poppins-Bold.ttf);
// }

// @font-face {
//   font-family: poppins-extra-bold;
//   src: url(../assets/fonts/poppins/Poppins-ExtraBold.ttf);
// }

// @font-face {
//   font-family: poppins-black;
//   src: url(../assets/fonts/poppins/Poppins-Black.ttf);
// }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

// ---------------| HEADING STYLES |--------------
// h1 {
//   font-size: 36px;
// }

h2 {
  font-size: 28px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 21px;
}

// h5 {
//   font-size: 20px;
// }

h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
}
// ------------------------------------------


