//BASE
@import "../base/variables";
@import "../base/mixin";
@import "../base/button";

@mixin menu-icon-style {
    content: "";
    width: 18px;
    height: 20px;
    margin-bottom: 3px;
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
}

body {
    background-color: rgba(227, 80, 50, 0.02) !important;
}

.amplify-card {
    background-color: $orange  !important;
}

header {
    &.navbar-header {
        @media (max-width: 991.98px) {
            height: 80px;
        }
        .burger-menu {
            display: none;
        }

        .navbar-brand {
            .df-logo {
                width: 150px;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .navbar-menu-wrapper {
            @media (max-width: 991.98px) {
                width: 100%;
                overflow: inherit;
                display: flex;
                transform: translate(0);
                top: auto;
                box-shadow: 0 0 25px rgb(26 108 225 / 10%);
                border-radius: 8px;
            }

            .navbar-menu-header {
                @media (max-width: 991.98px) {
                    display: none;
                }
            }

            .content-search {
                @media (max-width: 991.98px) {
                    display: none;
                }
            }

            .nav {
                &.navbar-menu {

                    @media (max-width: 991.98px) {
                        width: 75%;
                        display: flex;
                        flex-flow: row wrap;
                        padding: 15px 0 5px 0;
                    }

                    li {
                        &.nav-item {
                            @media (max-width: 991.98px) {
                                width: calc(100% / 3);
                                margin: 0;
                            }

                            a {
                                &.nav-link {
                                    color: $black;

                                    .desktop_text {
                                        display: block;

                                        @media (max-width: 991.98px) {
                                            display: none;
                                        }
                                    }

                                    .mobile_text {
                                        display: none;
                                        @media (max-width: 991.98px) {
                                            display: block;
                                        }
                                    }

                                    @media (max-width: 991.98px) {
                                        font-size: 12px;
                                        padding: 0 15px;
                                    }

                                    &:hover {
                                        color: $orange;
                                    }

                                    @media (max-width: 991.98px) {
                                        &.icons {
                                            display: flex;
                                            flex-flow: column;
                                            align-items: center;
                                            justify-content: center;
                                            margin-bottom: 5px;
                                            position: relative;

                                            &:after {
                                                content: "";
                                                width: 1px;
                                                height: 71px;
                                                position: absolute;
                                                right: 0;
                                                background: #e3e3e3;
                                                
                                            }
                                        }

                                        &.screen-icon {
                                            &::before {
                                                @include menu-icon-style;
                                                background-image: url(../../assets/images/screen.svg);

                                            }
                                        }

                                        &.watchlist-icon {
                                            &::before {
                                                @include menu-icon-style;
                                                background-image: url(../../assets/images/wishlist-Icon1.png);
                                                
                                            }
                                        }

                                        &.demat-icon {
                                            &::before {
                                                @include menu-icon-style;
                                                width: 25px;
                                                background-image: url(../../assets/images/agreement.png);
                                            }
                                        }

                                    }


                                }
                            }
                        }
                    }
                }
            }

            .dropdown {
                width: auto;
                display: flex;
                align-items: center;
                margin: 0;

                button {
                    white-space: nowrap;
                    @include primary-button;

                    &:hover {
                        color: $orange!important;
                    }

                    @media (max-width: 991.98px) {
                        background: transparent;
                        border: 0;
                        border-radius: 0;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 5px;
                        font-size: 12px;
                        font-weight: normal;
                        padding: 0 15px;
                        color: #001737!important;
                        border: 0!important;

                        &:before {
                            content: "";
                            background: url(../../assets/images/account.png) no-repeat;
                            background-size: 100%;
                            width: 18px;
                            height: 20px;
                            margin-bottom: 6px;
                        }

                        &:hover {
                            color: $black!important;
                        }

                        
                    }
                    span {
                        &.desktop__only {
                            @media (max-width: 991.98px) {
                                display: none;
                            }
                        }
                    }
                    


                }

                @media (max-width: 991.98px) {
                    .icons {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        border: 0;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    .user-icon {
                        &::before {
                            @include menu-icon-style;
                            width: 23px;
                            height: 26px;
                            margin-bottom: 0;
                            background-image: url(../../assets/images/account.png);
                        }
                    }
                }



                .dropdown-menu {

                    &.dropdown-menu-right {
                        button {

                            &.dropdown-item {
                                background: transparent;
                                color: $black;
                                border: 0;
                                padding: 0;

                                &::before {
                                    display: none;
                                }
                            }
                            
                        }
                    }

                    
                    @media (max-width: 991.98px) {
                        margin-right: 0;
                        margin-top: 0;
                        width: 100%;
                        padding: 40px 20px;
                        ;
                        position: fixed;
                        top: inherit;
                        bottom: 0;
                    }

                    // &:after {
                    //     content: '';
                    //     width: 15px;
                    //     height: 15px;
                    //     background: url(../../assets/images/close.png) no-repeat;
                    //     background-size: 100%;
                    //     position: absolute;
                    //     top: 15px;
                    //     right: 20px;
                    //     border: 0;
                    // }

                }
            }
        }
    }

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*Pavan*/
.nav-item a {
    text-transform: uppercase;
}

body {
    background-color: rgba(26, 108, 225, 0.03);
}

.navbar-header {
    border-bottom-color: #e5eefc;
    box-shadow: 0 0 25px rgba(26, 108, 225, 0.1);
}

.navbar-header .navbar-right .dropdown-menu {
    border-color: #e5eefc;
    border-top-color: transparent;
    box-shadow: 0 15px 15px rgba(26, 108, 225, 0.1);
}

.navbar-header .navbar-right .dropdown-notification .dropdown-menu,
.navbar-header .navbar-right .dropdown-message .dropdown-menu {
    margin-top: 18.5px;
}

.navbar-header .navbar-right .dropdown-notification .dropdown-menu::before,
.navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
    opacity: 0;
}

.dropdown-profile .dropdown-menu {
    margin-top: 13.5px;
}

.dropdown-profile .dropdown-menu::before {
    opacity: 0;
}

@media (min-width: 992px) {
    .navbar-menu-sub {
        padding-top: 10px;
        border-color: #e5eefc;
        border-top-color: transparent;
        box-shadow: 0 15px 15px rgba(26, 108, 225, 0.1);
    }
}

.navbar-menu-sub::before {
    opacity: 0;
}

@media (min-width: 992px) {
    .navbar-menu-sub {
        margin-top: 0;
    }
}

.card {
    border-color: transparent;
    box-shadow: 0 0 25px rgba(26, 108, 225, 0.1);
}

.card-header,
.card-footer,
.list-group-item,
.table tr th,
.table tr td,
.bd {
    border-color: #e9f1fc;
}

.__page_head_bar {
    .title {
        h2 {
            font-size: 20px;
        }
    }
    .save_query {
        button {
            @include tablet-landscape {
                width: 100%;
                margin: 10px 0;
            }
        }
    }
}

.content.content-fixed {
    @media (min-width: 992px) {
        margin-top: 0;
        padding-top: 90px;
    }
    &.screener_main_layout {
        @media (min-width: 992px) {
            min-height: calc(100vh - 122px);
        }
    }
}