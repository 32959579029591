//BASE
@import "../base/mixin";

.__manage_columns {

    .column_btn_listing {
        @include tablet-landscape {
            padding: 0!important;
            margin-bottom: 10px!important;
        }
    }
    .save_cancel {
        @include tablet-landscape {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            button {
                width: calc(50% - 5px);
                margin: 0;
            }
        }
    }

    .reset_button {
        @include tablet-landscape {
            button {
                width: 100%;
            }
        }
    }

    .search_field {
        @include tablet-landscape {
            width: 100%;
        }
        .search-form {
            @include tablet-landscape {

            }
        }
    }

    .horizontal_tabs {
        @include tablet-landscape {
            width: 100%;
            overflow: hidden;
        }

        .amplify-tabs {
            @include tablet-landscape {
                overflow: hidden;
                overflow-x: auto;
                white-space: nowrap;
            }

            button.amplify-tabs-item {
                position: relative;
                top: -2px;
            }
        }
    }
}