//BASE
@import "../base/variables";
@import "../base/mixin";
@import "../base/typography";
@import "../base/table";
@import "../base/button";

.home {
    .content_box {
        width: 100%;
        text-align: center;
        margin-top: 40px;

        h1 {
            color: $orange;
            font-weight: bold;
            font-size: 35px;

            @media (max-width: 991.98px) {
                font-size: 28px;
                margin-bottom: 10px;
            }
        }

        p {
            padding: 0 20vw;
            @media (max-width: 991.98px)  {
                padding: 0;
            }
        }
    }

    .search_section {
        margin-top: 40px;

        .search_field_group {
            .amplify-searchfield {
                .amplify-field-group.amplify-field-group--horizontal {
                    transition: all 0.5s;
                    box-shadow: 0px 1px 10px 3px rgb(226 226 226 / 50%);
                    border-radius: 15px;

                    .amplify-input {
                        border: 0;
                        padding: 10px 20px;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .amplify-field-group__outer-end {
                        button {
                            border: 0;
                            background: $orange;

                            .amplify-icon {
                                svg {
                                    path {
                                        color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .trending-stocks-container {
            .item {
                &.trendingStocks {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: row wrap;

                    span {
                        
                        @media (max-width: 991.98px) {
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                    }

                    ul {
                        &.trending-wrap {
                            display: flex;
                            flex-flow: row wrap;
                            list-style-type: none;
                            margin: 0;
                            padding-left: 0;
                            justify-content: flex-start;

                            @media (max-width: 991.98px) {
                                justify-content: center;
                            }

                            .stocks-item {
                                width: auto;
                                height: auto;
                                font-size: 11.5px;
                                text-align: center;
                                margin: 6px;

                                a {
                                    color: #000;
                                    padding: 5px 10px;
                                    background: #f5f5f5;
                                    border-radius: 4px;

                                    @include tablet-landscape {
                                        padding: 10px 10px;
                                        display: inline-block;
                                        min-width: 100px;
                                        border: 1px solid #e7e4e4;
                                        font-size: 12px;
                                    }

                                    &:hover {
                                        background: $orange;
                                        color: white;
                                    }
                                }
                            }



                        }
                    }
                }
            }
        }
    }

    .built_screen {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (max-width: 991.98px) {
            background: white;
            padding: 20px 8px;
            box-shadow: 0 0 25px rgba(26, 108, 225, 0.1);
        }

        h2 {
            @media (max-width: 991.98px) {
                margin-bottom: 20px;
            }
        }

        .button_group {
            @media (max-width: 991.98px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .btn-primary {
                @media (max-width: 991.98px) {
                    margin: 5px;
                }
                &.yellow  {
                    background-color: $yellow;
                    border-color: $yellow;
                    color: $black;
                    transition: all 0.3s ease;

                    &:hover {
                        background-color: $orange;
                        border-color: $orange;
                        color: $white;
                    }
                }
            }
        }

        
    }

    .top_performence {
        margin-bottom: 40px;

        h2 {
            margin-bottom: 25px;
        }

        .card {
            @media (max-width: 991.98px) {
                margin-bottom: 15px;
            }
            .card-body {
                margin-bottom: 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-flow: column;

                h5 {
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                a {
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .nifty_stock {
        h2 {
            margin-bottom: 25px;
        }
        .card {
            .card-body {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-flow: column;
                h5 {
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                a {
                    &:first-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}